import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Background from '../../components/background'
import Products from '../../components/products'
import ProductCard from '../../components/product_card'
import News from '../../components/news'
import NewsTenderCard from '../../components/news_tender_card'

const IIoTPage = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('iiot-title')} language={i18n.language} />
          <Background title={t('division-iiot')} backgroundTextPic="bg-section-background-iiot" translationKey="iiot-background-card-list" />
          <Products>
              <ProductCard productName="IoTrace" productPic="IIOT-IoTrace-card" internal path="/products/iotrace" translationKey="iiot-iotrace-desc-card-list" />
              <ProductCard productName={t('arrowhead-framework')} productPic="IIOT-Arrowhead-card" internal path="/products/arrowhead" translationKey="iiot-arrowhead-desc-card-list" />
              <ProductCard productName={t('arrowhead-tools')} productPic="IIOT-Arrowhead-Tools-card" internal path="/products/arrowhead-tools" translationKey="iiot-arrowhead-tools-desc-card-list" />
          </Products>
          <News>
              <NewsTenderCard title={t('news-aims5-project-title')} tenderPic="national-research" description={t('news-aims5-project-desc')} path="/projects/aims5" />
              <NewsTenderCard title={t('news-arrowhead-fpvn-project-title')} tenderPic="national-research" description={t('news-arrowhead-fpvn-project-desc')} path="/projects/arrowhead-fpvn" />
              <NewsTenderCard title="Indoor Positioning Project" tenderPic="national-research" description={t('news-ips-desc')} />
              <NewsTenderCard title="Productive4.0 Project" tenderPic="national-research" description={t('news-productive40-desc')} />
              <NewsTenderCard title="Mantis Project" tenderPic="mantis" description={t('news-mantis-desc')} />
          </News>
      </Layout>
  )
}

export default IIoTPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "iiot", "products", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
